// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacidad-tsx": () => import("./../../../src/pages/privacidad.tsx" /* webpackChunkName: "component---src-pages-privacidad-tsx" */),
  "component---src-pages-productos-bookeye-4-v-2-tsx": () => import("./../../../src/pages/productos/bookeye4-v2.tsx" /* webpackChunkName: "component---src-pages-productos-bookeye-4-v-2-tsx" */),
  "component---src-pages-productos-widetek-25-tsx": () => import("./../../../src/pages/productos/widetek-25.tsx" /* webpackChunkName: "component---src-pages-productos-widetek-25-tsx" */),
  "component---src-pages-productos-widetek-36-art-tsx": () => import("./../../../src/pages/productos/widetek-36art.tsx" /* webpackChunkName: "component---src-pages-productos-widetek-36-art-tsx" */),
  "component---src-pages-productos-widetek-48-c-tsx": () => import("./../../../src/pages/productos/widetek-48c.tsx" /* webpackChunkName: "component---src-pages-productos-widetek-48-c-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */)
}

